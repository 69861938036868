import { useToast } from "@chakra-ui/react";

const useCustomToast = () => {
  const toast = useToast();
  const showToast = (title, status, description, isClosable = true) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: isClosable,
      position:"top-right"
    });
  };

  return showToast;
};

export default useCustomToast;
