import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";
import "firebase/functions";
export const STATE_CHANGED = firebase.storage.TaskEvent.STATE_CHANGED;

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCspOsumxpJq570v4WDkD6igBhO1PjO7IQ",
    authDomain: "skilsoar.firebaseapp.com",
    databaseURL: "https://skilsoar-default-rtdb.firebaseio.com",
    projectId: "skilsoar",
    storageBucket: "skilsoar.appspot.com",
    messagingSenderId: "559832514095",
    appId: "1:559832514095:web:3cf1ef61f58f68a34890da",
    measurementId: "G-3TPHXTHLTV",
  });
}
const functions = firebase.functions();
const storage = firebase.storage();
const firestore = firebase.firestore();
export { storage, functions, firestore, firebase as default };