import React from "react";
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Box,
} from "@chakra-ui/react";
import NextLink from 'next/link';
const ChangeRoleModal = ({ isChange,setIsChange,userData }) => {
  const onClose = () =>{ 
    setIsChange(false)
  };
  const cancelRef = React.useRef();

  return (
    <Box as="span">
      <AlertDialog
        isOpen={isChange}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay className="delete-account-modal">
          <AlertDialogContent className=" popup-small-width px-4">
            <form className="mt-6">
              <h3 className="text-center heading-color">
                Are you sure! <br/>
                You want to change role from {userData?.role} to {userData?.role==='mentee'?'mentor':'mentee'}.
              </h3>
              <AlertDialogFooter className="!justify-center">
                <Button
                  type="button"
                  className="dark-blue-btn"
                  onClick={() => {
                    setIsChange(true)
                  }}
                >
                  Yes
                </Button>
                <NextLink
                  href="javascript:void(0)"
                  className="blue-outline-btn"
                  onClick={onClose}
                >
                  Cancel
                </NextLink>
              </AlertDialogFooter>
            </form>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ChangeRoleModal;
