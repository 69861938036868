import { Box, Flex, Button } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import NextLink from "next/link";
import { usePathname } from 'next/navigation';
import { useState } from "react";
import { MdClose, MdMenu } from "react-icons/md";
import { useAuth } from "@/lib/auth";
import { Disclosure } from "@headlessui/react";
import { IoChevronDownSharp } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { BiLogOut } from "react-icons/bi";
import { BsChatLeftDots } from "react-icons/bs";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { useRouter } from 'next/router';
import useSWR from "swr";
import firebase from "firebase";
import fetcher from "@/utils/fetcher";
import ChangeRoleModal from "@/components/modal/ChangeRole";
import useCustomToast from "hooks/useCustomToast";

const loginHeader = [
  { name: "Home", link: "/" },
  { name: "FAQ's", link: "/faq" },
  { name: "Contact us", link: "/contact" },
  { name: "Chat", link: "/chat" },
  { name: "Become a mentor", link: "/mentor" },
  {name: "profile", link: "/profile", subMenu: [
      { name: "Dashboard", link: "/dashborad" },
      { name: "Logout", link: "/" },
    ]
  }
]
const adminHeader = [
  { name: "Home", link: "/" },
  { name: "FAQ's", link: "/faq" },
  {name: "profile", link: "/profile", subMenu: [
      { name: "Dashboard", link: "/dashborad" },
      { name: "Logout", link: "/" },
    ]
  }
]
const guestHeader = [
  { name: "Home", link: "/" },
  { name: "FAQ's", link: "/faq" },
  { name: "Contact us", link: "/contact" },
  { name: "Login", link: "/login" },
  { name: "Sign up", link: "/signup" },
]
const Header = ({setUser}) => {
  const router = useRouter();
  const currentPath = usePathname();
  const toast = useCustomToast();
  const selectedPath = currentPath?.split("/")[1];
  const [selectedOption, setSelectedOption] = useState("");
  const [userData, setDisplayName] = useState('');
  const [chatCount, setTotalCount] = useState(0);
  const [isChange, setIsChange] = useState(false);
  const firestore = firebase.firestore();
  const db = firestore;
  const { user, signout } = useAuth();
  const { data,mutate } = useSWR(`/api/user/${user?.uid}`, fetcher);
  useEffect(()=>{
    if (data?.user && data?.user?.is_activated) {
      setDisplayName(data.user);
      setUser(data?.user);
    }else{
      setDisplayName(null);
      setUser(null);
    }
  }, [data?.user]);
  useEffect(()=>{
    const unsubscribe = db.collection(`users/${user?.uid}/chat`)
      .onSnapshot((snapshot) => {
        let sData = null;
        let countTotal = 0;

        snapshot.forEach((doc) => {
          sData = { id: doc.id, ...doc.data() };
          if (sData.count) {
            countTotal += sData.count; 
          }
        });

        setTotalCount(countTotal);
      }, (error) => {
        console.error('Error fetching sessions:', error);
      });

    return () => unsubscribe(); 
  }, [userData]);
  const switchRole=()=>{
    if(userData?.is_approved===true){
      setIsChange(true);
    }else if(userData?.is_applied && !userData?.is_approved && !userData?.reject_reason){
      router.push("/become-mentor/steps/7");
    }else{
      router.push("/become-mentor");
    }
  }
  const changedRole=async(val)=>{
    if(val===true){
      let d={...userData,role:userData?.role==='mentee'?'mentor':'mentee'};
      await db.collection(`users`).doc(user?.uid).update(d)
      toast("Successfully changed role!","success",'',true);
      await mutate(`/api/user/${user?.uid}`);
      if (router.pathname === '/dashboard') {
        router.push('/my-sessions').then(() => {
          router.push('/dashboard');
        });
      } else {
        router.push('/dashboard');
      }
    }
    setIsChange(false);
  }
  const togglePannel=()=>{
    const ele=document.getElementById('disclosure-header-panel');
    if(ele){
      document.getElementById('monile-toggle')?.click();
    }
  }
  return (
    <>
      <Flex
        as="header"
        w="100%"
        h="auto"
        borderBottom="1px"
        borderColor="#DBEBFF"
        className="header"
        padding={"0"}
      >
        <Flex
          backgroundColor="white"
          alignItems="center"
          margin="0 auto"
          w={["100%", "100%", "100%", "100%"]}
          justifyContent="space-between"
          py={2}
        >
          <Disclosure as="nav" className="bg-white z-[999] w-full">
            {({ open }) => (
              <>
                <Box className="container mx-auto">
                  <Box
                    className={`flex justify-between ${
                      currentPath === "/" ? "bg-white sm:bg-[#ffffff94]" : ""
                    } rounded-full py-3 ${
                      selectedPath === "dashboard" ? "" : ""
                    }`}
                  >
                    <Box className="flex justify-between w-full">
                      <Box className="flex-shrink-0 flex items-center">
                        <NextLink href="/" passHref onClick={()=>togglePannel()}>
                          <Image
                            src="/assets/main-logo.svg"
                            alt="Skilsoar"
                            className="logo-img mobile-header-logo"
                            style={{ width: "170px", cursor: "pointer" }}
                          />
                        </NextLink>
                      </Box>
                      <Box className="hidden sm:ml-6 md:flex sm:space-x-8 header-menu-list items-center">
                        {((userData) ?((userData?.role==='owner')?adminHeader: loginHeader) : guestHeader).map((menu, index) =>
                            menu.link === "/profile" ? (
                              <Menu key={index}>
                                <MenuButton
                                  className="dark-blue-btn"
                                  as={Button}
                                  rightIcon={<IoChevronDownSharp />}
                                >
                                  <Box className="flex items-center gap-1 capitalize">
                                    <span className="w-[24px] h-[24px] rounded-full circle-border ">
                                      <img
                                        className="w-[100%] h-[100%] object-cover rounded-full"
                                        src={userData && userData?.profile_pic?userData?.profile_pic:'/assets/no_profile.jpg'}
                                      ></img>
                                    </span>
                                    {userData?(userData?.first_name+(userData?.last_name?' '+userData?.last_name:'')):''}
                                  </Box>
                                </MenuButton>
                                <MenuList className="dropdown-lists">
                                  <MenuItem
                                    className="border-b"
                                    onClick={() => router.push("/dashboard")}
                                  >
                                    {" "}
                                    <span className="pr-2">
                                      <RxDashboard />
                                    </span>{" "}
                                    Dashboard
                                  </MenuItem>
                                  <MenuItem onClick={() => signout()}>
                                    {" "}
                                    <span className="pr-2">
                                      <BiLogOut />
                                    </span>{" "}
                                    Logout
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            ) :((menu.link==='/mentor') && (userData?.role!=='owner')) ? (
                              <Button
                                key={index}
                                className="blue-outline-btn switch-mentor-btn"
                                onClick={() => switchRole()}
                              >
                                {(userData?.is_applied===true) && (userData?.is_approved===true)?`Switch to ${userData?.role === "mentee" ? "Mentor" : "Mentee"}`:'Become a mentor'}{" "}
                              </Button>
                            ): (
                              <NextLink
                                href={menu.link}
                                key={index}
                                className={`${
                                  currentPath === menu.link ? "heading-color" : ""
                                } ${
                                  menu.link === "/login"
                                    ? "blue-outline-btn"
                                    : ""
                                } ${
                                  menu.link === "/signup" ? "dark-blue-btn" : ""
                                } inline-flex items-center px-1 pt-1 text-base font-light`}
                              >
                                {(menu.link === "/chat") && (userData?.role!=='owner')?(
                                  <Box className="chat-section mobile-hidden-link relative pl-0">
                                    {chatCount>0 && <span className="absolute chat-notification top-[-10px] right-[-5px]">
                                      {chatCount}
                                    </span>}
                                    <BsChatLeftDots />
                                  </Box>
                                ) : (
                                  menu.name
                                )}
                              </NextLink>
                            )
                          )}
                      </Box>
                    </Box>

                    {/* Mobile menu button */}
                          
                    <Box className="-mr-2 mob-burger-menu flex items-center md:hidden gap-[15px]">
                    {((userData) ? ((userData?.role==='owner')?adminHeader: loginHeader)  : guestHeader)?.filter((i)=>i.name !="Login" || i?.name !="Sign Up")?.map((menu, index) =><React.Fragment key={index}>
                            {menu.link === "/profile" ? (
                              <Menu key={index}>
                                <MenuButton
                                  className="dark-blue-btn"
                                  as={Button}
                                  rightIcon={<IoChevronDownSharp />}
                                  onClick={()=>togglePannel()}
                                >
                                  <Box className="flex items-center gap-1">
                                    <span className="w-[24px] h-[24px] rounded-full circle-border">
                                      <img
                                        className="w-[100%] h-[100%] object-cover rounded-full"
                                        src={userData && userData?.profile_pic?userData?.profile_pic:'/assets/no_profile.jpg'}
                                      ></img>
                                    </span>
                                    {userData?(userData?.first_name+(userData?.last_name?' '+userData?.last_name:'')):''}
                                  </Box>
                                </MenuButton>
                                <MenuList className="dropdown-lists">
                                  <MenuItem
                                    key={1}
                                    className="border-b"
                                    onClick={() => router.push("/dashboard")}
                                  >
                                    {" "}
                                    <span className="pr-2">
                                      <RxDashboard />
                                    </span>{" "}
                                    Dashboard
                                  </MenuItem>
                                  <MenuItem key={2} onClick={() => signout()}>
                                    {" "}
                                    <span className="pr-2">
                                      <BiLogOut />
                                    </span>{" "}
                                    Logout
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            ) : (menu.link === "/mentor")?(((userData?.role!=='owner')) ? (
                              <></>
                            ):'' ): (
                              <NextLink
                                href={menu.link}
                                key={index}
                                onClick={()=>togglePannel()}
                              >
                                {(menu.link === "/chat") && ((userData?.role!=='owner'))?(
                                  <Box className="chat-section  relative pl-0">
                                    {chatCount>0 && <span className="absolute chat-notification top-[-10px] right-[-5px]">
                                      {chatCount}
                                    </span>}
                                    <BsChatLeftDots />
                                  </Box>
                                ) : (
                                  menu?.name == "Chat"  ? menu?.name : ""
                                )}
                              </NextLink>
                            )}
                            </React.Fragment>
                          )}
                    <Box className="hidden sm:ml-6 md:flex sm:space-x-8 header-menu-list items-center">
                      </Box>
                      <Disclosure.Button id="monile-toggle" className="inline-flex items-center justify-center pl-0 p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <MdClose
                            className="block h-8 w-8 text-black"
                            aria-hidden="true"
                          />
                        ) : (
                          <MdMenu
                            className="block h-8 w-8 text-black"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </Box>
                  </Box>
                </Box>
                <Box className="container mx-auto">
                  <Disclosure.Panel className="md:hidden" id="disclosure-header-panel">
                    <Box className="pt-2 pb-3 space-y-1 flex flex-col gap-[20px] mt-[30px]">
                      {((userData) ? loginHeader : guestHeader)?.map((menu, index) =><React.Fragment key={index}>
                        {menu.link === "/profile" ? (
                          <></>
                        ) :((menu.link==='/mentor') && (((userData?.role!=='owner')))) ? (
                          <Button
                            key={index}
                            className="blue-outline-btn switch-mentor-btn"
                            onClick={() =>{ switchRole(); togglePannel()}}
                          >
                            {(userData?.is_applied===true) && (userData?.is_approved===true)?`Switch to ${userData?.role === "mentee" ? "Mentor" : "Mentee"}`:'Become a mentor'}{" "}
                          </Button>
                        ): (
                          menu.link !== "/login" && menu.link !== "/signup" && menu.link !== "/chat" && (
                            <NextLink href={menu.link} key={index}  onClick={()=>{togglePannel()}}>
                              {menu.name}
                            </NextLink>
                          )
                        )}
                        </React.Fragment>
                      )}

                      {/* Separate div for Login and Sign up buttons */}
                      {!userData && <Box className="flex justify-start   items-center gap-4 mt-4">
                        <NextLink key={1} href="/login" onClick={()=>{togglePannel()}}>
                          <Button className="blue-outline-btn">Login</Button>
                        </NextLink>
                        <NextLink key={1} href="/signup" onClick={()=>{togglePannel()}}>
                          <Button className="dark-blue-btn">Sign up</Button>
                        </NextLink>
                      </Box>}
                    </Box>
                  </Disclosure.Panel>
                </Box>
              </>
            )}
          </Disclosure>
        </Flex>
      </Flex>
      {isChange && <ChangeRoleModal isChange={isChange} setIsChange={(val)=>changedRole(val)} userData={userData}/>}
    </>
  );
};

export default Header;
