"use client";

import Link from "next/link";
import { useRouter } from "next/router";
import NextLink from "next/link";
import { useAuth } from "@/lib/auth";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { Box, Img } from "@chakra-ui/react";
/* eslint-disable @next/next/no-img-element */

const footerList1 = [
  { name: "Home", link: "/" },
  { name: "FAQ’s", link: "/faq" },
  { name: "Login", link: "/login" },
  { name: "Create an account", link: "/signup" },
  { name: "Contact us", link: "/contact" },
];
const footerList1a = [
  { name: "Home", link: "/" },
  { name: "FAQ’s", link: "/faq" },
  { name: "Contact us", link: "/contact" },
];
const footerList1b = [
  { name: "Home", link: "/" },
  { name: "FAQ’s", link: "/faq" },
];
const footerList2 = [
  { name: "Term & Condition", link: "/term-and-conditions" },
  { name: "Privacy Policy", link: "/privacy-policy" },
  { name: "Legal Notice", link: "/legal-notice" },
];
const Footer = ({userData}) => {
  const pathname = useRouter();
  const selectedPath = pathname;
  const { user } = useAuth();
  return (
    <footer
      className={`footer-wrapper ${
        selectedPath === "/dashboard" ? "hidden" : "block"
      }`}
    >
      <Box className="container mx-auto py-12 relative">
        <Img
          src="/footer-before-assets.png"
          alt="footer-before-assets"
          className="footer-before-assets"
        />
        <Box className="flex gap-4 flex-col">
          <Box className="w-full flex flex-col items-center justify-between">
            <Box className="mx-auto">
              <NextLink href={'/'}>
                <img
                  src="/footer-logo.svg"
                  alt="logo"
                  className="footer-logo"
                />
              </NextLink>
            </Box>
          </Box>
          <Box className="w-full">
            <Box className="">
              <Box className="flex justify-center text-center mx-auto gap-10 footer-links">
                {(user?((userData?.role==='owner')?footerList1b:footerList1a): footerList1).map(f=><Box key={f?.name} className="text-base text-white font-light pr-2 py-1">
                  <NextLink href={f?.link}>{f?.name}</NextLink>
                </Box>)}
              </Box>
              <Box className="w-full flex flex-col gap-4">
                <Box className="flex items-center justify-center gap-10 mt-25 footer-social-icons">
                  <NextLink target="_blank" href="https://facebook.com/">
                    <FaFacebookF
                      color="#194172"
                      size={22}
                    />
                  </NextLink>
                  <NextLink target="_blank" href="https://in.linkedin.com/">
                    <FaLinkedinIn
                      color="#194172"
                      size={22}
                    />
                  </NextLink>
                  <NextLink target="_blank" href="https://www.instagram.com/">
                    <FaInstagram
                      color="#194172"
                      size={22}
                    />
                  </NextLink>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="container mx-auto">
        <Box className="footer-copyright-wrap">
          <Box className="copyright-text">
            <p className="text-white font-light text-sm mb-0">
              © 2024{" "}
              <NextLink href="/">
                <u>SkilSoar</u>
              </NextLink>{" "}
              | All rights reserved
            </p>
          </Box>
          <Box className="text-white footer-static-links">
            {footerList2.map((f, i) => (
              <span key={i}>
                <Link target="_blank" href={f.link} className="text-white font-light text-sm footer-links">
                  {f.name}
                </Link>
                {i < footerList2.length - 1 ? "|" : ""}
              </span>
            ))}
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
