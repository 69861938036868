import React, { useState,useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";
import { AuthProvider, useAuth } from "@/lib/auth";
//import "../styles/grid.css";
import "../styles/global.css";
import "../styles/custom.css";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/hightlight.css";
import "../styles/date-picker.css";
import Header from "@/components/templates/Header";
import Footer from "@/components/templates/Footer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import toast, { Toaster } from "react-hot-toast";
const App = ({ Component, pageProps }) => {
  const user = useAuth();
  const CLIENT_ID ="691895213501-ee0lk258nt1d05oou0muo33hjvbucu30.apps.googleusercontent.com";
  const [userData,setUserData]=useState(user);
  return (
    <ChakraProvider>
      {/* <GoogleOAuthProvider clientId={CLIENT_ID}> */}
      <Toaster position="top-right" reverseOrder={false} />
      <AuthProvider>
        <Header setUser={setUserData}/>
        <Component {...pageProps} />
        <Footer userData={userData}/>
      </AuthProvider>
      {/* </GoogleOAuthProvider> */}
    </ChakraProvider>
  );
};

export default App;
