import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from "./firebase";
import { createUser } from "./db";
import Router, { useRouter } from "next/router";
import cookies from "js-cookie";
import useCustomToast from "hooks/useCustomToast";
import { Alert } from "@mui/material";
import { updateUser,getUserByUid } from "./db";
const authContext = createContext();
export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(() => {
    if (typeof window !== "undefined" && window.localStorage.getItem('authUser')) {
      return JSON.parse(localStorage.getItem('authUser'));
    }
    return null;
  });
  const [isAuthenticated, setisAuthenticated] = useState(() => {
    if (typeof window !== "undefined" && window.localStorage.getItem('skillsour-auth')) {
      return true;
    }
    return false;
  });
  const [isLoading, setisLoading] = useState(false);
  const router = useRouter();
  const showToast = useCustomToast();
  const handleCreateUser = (rawUser, username, uid) => {
    if (rawUser) {
      const user = formatUser(rawUser, uid);
      user.username = username;
      user.role = "mentee";
      user.is_activated=true;
      const { token, ...userWithoutToken } = user;
      createUser(user.uid, userWithoutToken);
      setUser(user);
      // sendEmailVerification;

      return user;
    } else {
      setUser(false);
      return false;
    }
  };

const handleUpdateUser = async(rawUser)=>{
  if(rawUser){
    const user = formatUser(rawUser, rawUser?.uid);
    await updateUser(user, rawUser?.uid);
  }
}

  const handleCreateMentor = (rawUser, firstname, lastname) => {
    const user = formatUser(rawUser, rawUser?.uid);
    user.first_name = firstname;
    user.last_name = lastname;
    user.role = "mentor";
    const { token, ...userWithoutToken } = user;
    createUser(user.uid, userWithoutToken);
    //return user;
  };

  const handleUser = (rawUser) => {
    try {
      if (rawUser) {
        setisLoading(true);
        setisAuthenticated(true);
        const user = formatUser(rawUser, rawUser?.uid);
        setUser(user);
        localStorage.setItem('authUser',JSON.stringify(user));
        localStorage.setItem('skillsour-auth',true);
        return user;
      } else {
        setUser(false);
        localStorage.removeItem("authUser");
        localStorage.removeItem("skillsour-auth");
        router.push("/login");
        return false;
      }
    } catch (error) {
      setisLoading(false);
      setisAuthenticated(false);
    }finally{
      setisLoading(false);
    }
  };

  const signinWithGitHub = () => {
    return firebase
      .auth()
      .signInWithPopup(new firebase.auth.GithubAuthProvider())
      .then((response) => handleUser(response.user));
  };

  const signin = async(email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async(response) => {
        let u=await getUserByUid(response?.user?.uid);
        console.log(u);
        
        if(u && (u?.is_activated)){
          setisAuthenticated(true);
          await handleUser({...response?.user,...u});                                                                                                                  
          return true;
        }else{               
          await handleUser(false);
          await firebase.auth().signOut();                                                      
          setisAuthenticated(false);
          showToast(                                          
            "Account is not activated!",
            "error",
            "",                               
            true                                                                              
          );
          return false;
        }
      }).catch(async(err)=>{
        console.log("ERRRRR",err)
        await handleUser(false);
        showToast(
          "Invalid Credentials",
          "error",
          "The login credentials provided are incorrect. Please try again.",
          true
        );
        return false;
      }
    )
  };

  const signup = async (email, password, username, userData ) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        let u=handleCreateUser(userData, username, response.user?.uid);
        setisAuthenticated(true);
        // response.user.sendEmailVerification();
        router.push("/signup-success");
        response.user.updateProfile({
          displayName: username,
        });
        return u;
      });
  };

  const createMentor = async(email, password, firstname, lastname) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        handleCreateMentor(response.user, firstname, lastname);
      });
  };

  const signout = async() => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        handleUser(false);
        setUser(false);
        localStorage.removeItem("authUser");
        localStorage.removeItem("skillsour-auth");
        setisAuthenticated(false);
        router.push("/");
      });
  };

  const sendEmailVerification = () => {
    return firebase
      .auth()
      .sendEmailVerification({
        url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
      })
      .then(() => {
        console.log("sent email verificartion");
      })
      .catch(function (error) {
        console.log("Error Sent email verificartion");
      });
  };

  const updateUserEmail = (email) => {
    const user = firebase.auth().currentUser;
    return user.updateEmail(email).then(() => {
      return true;
    });
  };

  // const sendPasswordResetEmail = async(email) => {

  //   return firebase
  //     .auth()
  //     .sendPasswordResetEmail(email)
  //     .then((res) => {
  //       return res;
  //     }).catch((err)=>console.log("ERR", err))
  // };

 const sendPasswordResetEmail = async (email) => {
   try {
     // Check if the user exists in the Firestore users collection
     const userSnapshot = await firebase
       .firestore()
       .collection("users")
       .where("email", "==", email)
       .get();

     if (!userSnapshot.empty) {
       // If the user exists, send the password reset email
       await firebase.auth().sendPasswordResetEmail(email);
       console.log("Password reset email sent successfully");
       return {
         success: true,
         message: "Password reset email sent successfully",
       };
     } else {
       // User does not exist in Firestore
       console.log("User not found in the Firestore collection");
       return { success: false, message: "User not registered" };
     }
   } catch (error) {
     console.log("Error sending password reset email:", error);
     return { success: false, message: "An error occurred", error };
   }
 };

  const getUserToken = () => {
    const user = firebase.auth().currentUser;
    return user.getIdToken(true).then(function (idToken) {
      
    });
  };

  const confirmPasswordReset = async (password, code) => {
    const resetCode = code;
    try{
      let res = await firebase.auth().confirmPasswordReset(resetCode, password);
       showToast("Password reset successful", "success", "Your password has been reset successfully", true);
      router.replace("/")
    }catch(err){
      console.log("GOTT ERR", err.code)
      showToast("Link expired", "error", "The link you are using is either expired or used", true);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return {
    user,
    isLoading,
    signinWithGitHub,
    signout,
    signup,
    signin,
    sendEmailVerification,
    createMentor,
    updateUserEmail,
    getUserToken,
    sendPasswordResetEmail,
    confirmPasswordReset,
    handleUpdateUser,
    isAuthenticated,
    setisAuthenticated,
  };
}

const formatUser = (user, uid) => {
  return {
    uid: uid,
    email: user.email,
    token: user.Aa,
    first_name: user.firstName,
    last_name: user.lastName,
    phone_number: user.phone_number,
    career_focus: user.career_focus,
    industry: user.industry,
    vat: user.vat,
    zip_code: user.zip_code,
    googleId: user?.id,
    access_token: user?.access_token,
    role: user?.role,
    profile_pic: user?.profile_pic,
    is_activated: user?.is_activated,
    created_at: new Date()
  };
};
